<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>money-wallet-1</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M21.75,18v3a1.5,1.5,0,0,1-1.5,1.5H3A2.25,2.25,0,0,1,.75,20.25V3.75A2.25,2.25,0,0,1,3,1.5H18.75A1.5,1.5,0,0,1,20.25,3V5.25"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M21.75,18a1.5,1.5,0,0,0,1.5-1.5V12a1.5,1.5,0,0,0-1.5-1.5H18A3.75,3.75,0,0,0,18,18Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M18,13.875a.375.375,0,1,1-.375.375A.375.375,0,0,1,18,13.875"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M21.75,10.5V6.75a1.5,1.5,0,0,0-1.5-1.5H5.625a1.756,1.756,0,0,1-1.614-.895"
    />
  </svg>
</template>
